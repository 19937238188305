<template>
	<div class="product-library-main">
		<div class="data-content" v-loading="isLoading">
			<button class="add-btn" @click="addProductClick">添加产品</button>
			<div class="query-content">
				<div class=" flex flex-col-center flex-wrap">
					<div class="input-content flex flex-col-center">
						<span class="title-text">产品名称</span>
						<input class="input-text" type="text" placeholder="输入产品名称" v-model="nameText" />
					</div>
					<div class="input-content flex flex-col-center">
						<span class="title-text">产品分类</span>
						<el-cascader v-model="typeText" :options="proClass" collapse-tags :props="{value: 'id', label: 'name', multiple: true}" clearable></el-cascader>
					</div>
					<div class="input-content flex flex-col-center">
						<span class="title-text">状态</span>
						<el-select v-model="statusText" placeholder="请选择">
							<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
					<div class="input-content flex flex-col-center">
						<span class="title-text">创建时间</span>
						<div class="date-content flex flex-col-center flex-row-between">
							<el-date-picker v-model="timeList" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
							<i class="el-icon-date"></i>
						</div>
					</div>
					<div class="input-content flex flex-col-center">
						<span class="title-text">适用地区</span>
						<el-cascader v-model="addressText" :options="optionsAddress" collapse-tags :props="{value: 'id', label: 'name', multiple: true}" clearable></el-cascader>
					</div>
				</div>
				<div class="rest-btn-content flex flex-col-center">
					<button class="r-btn query-btn" @click="queryClick">查询</button>
					<button class="r-btn res-btn" @click="resterClick">重置</button>
				</div>
			</div>
			<div class="tabel-content">
				<el-table :data="tableData" ref="multipleTable" style="width: 100%" :header-cell-style="{background:'#F6F7F9'}"  @selection-change="selectionChange">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column label="产品信息" width="705">
						<template slot-scope="scope">
							<div class="flex flex-col-center">
								<!-- <el-checkbox v-model="checked"></el-checkbox> -->
								<div class="info-content flex flex-col-center">
									<img class="log-img" :src="scope.row.product_img[0]" alt="" />
									<div class="info-r">
										<p class="info-title-text">{{scope.row.name}}</p>
										<p class="info-type-text">{{scope.row.cate_node_name}}</p>
										<p class="info-content-text oe2">{{scope.row.feature}}</p>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="money" label="价格/收费方式" width="200">
						<template slot-scope="scope">
							<p class="text-red">{{'￥'+scope.row.price+'/'+scope.row.charge_method}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="状态" width="200">
						<template slot-scope="scope">
							<p :class="[scope.row.status==1?'text-blue':'text-red']">{{scope.row.status==1?'上架中':'已下架'}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="create_time" label="添加时间" width="200"></el-table-column>
					<el-table-column label="操作" width="200" fixed="right">
						<template slot-scope="scope">
							<div class="flex flex-col-center">
								<router-link :to="{path: '/addProduct', query:{type: 'edit', id: scope.row.id}}">
									<div class="btn-content flex flex-col-center">
										<img class="edit-img" src="@/assets/productLibrary/edit_icon.png" alt="" />
										<el-button type="text" size="small">编辑</el-button>
									</div>									
								</router-link>
								<div class="btn-content flex flex-col-center" @click="getUpdateStatu(scope.row.id, scope.row.status)">
									<img class="edit-img" src="@/assets/productLibrary/grounding_icon.png" alt="" />
									<el-button type="text" size="small">{{scope.row.status==1?'下架':'上架'}}</el-button>
								</div>
								<div class="btn-content flex flex-col-center" @click="deteleClick(scope.row.id)">
									<img class="edit-img" src="@/assets/productLibrary/del_icon.png" alt="" />
									<el-button type="text" size="small">删除</el-button>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page-content flex flex-col-center flex-row-between">
				<div class="">
					<el-checkbox v-model="isChecked" @change="selectAllClick"></el-checkbox>
					<button class="xj-text-btn" @click="selectAllStatu">下架</button>
				</div>
				<paging-item :pageSize="pageSize" :total="pageAllSize" :pageList="pageList" @pageEvent="currentClick" @updatePageEvent="updatePage"></paging-item>
			</div>
		</div>
<!--		<tip-popup :show="deteleData.show" @enterEvent="enterDelete" @closeEvent="deteleData.show=false"></tip-popup>-->
	</div>
</template>

<script>
	import address from "../questionnaire/components/address.js"
	import {timeFormat} from "@/utils/index.js"
	import pagingItem from "./components/paging-item.vue"
	import tipPopup from "./components/tip-popup.vue"
	export default{
		components: {
			pagingItem, tipPopup
		},
		data(){
			return{
				nameText: '',//产品名称
				typeText: [],//产品分类
				proClass: [],//产品分类总数据
				optionsAddress: [],//分类数据
				statusText: '',//状态
				statusList: [{ value: '1', label: '上架'}, { value: '2', label: '下架'}, { value: '0', label: '全部'}],
				timeList: [],//日期
				addressText: [],//适用地区
				pageList: [{value: '10', label: '10'},{value: '20', label: '20'},{value: '30', label: '30'}],//选择页面总条数据
				pageIndex: 1,//翻页 
				pageSize: 10,//一个页面总条数
				pageAllSize: 0,//数据总条数
				isLoading: false,//是否正在加载数据
				tableData: [],
				deteleData: {show: false, data: []},//删除提示
				isChecked: false,//是否全选
			}
		},
		mounted() {
			this.initData();
			this.getProClass();
			this.getAddressData();
		},
		methods:{
			initData() {
        console.log(this.typeText, 111111)
				this.isLoading = true;
				let data = {
					name: this.nameText,
          cate_id: this.typeText.length>0?this.typeText:'',
					status: this.statusText,
					start_time: this.timeList[0]?timeFormat(this.timeList[0], 'yyyy-mm-dd hh:MM:ss'):'',
					end_time: this.timeList[1]?timeFormat(this.timeList[1], 'yyyy-mm-dd hh:MM:ss'):'',
          area_id: this.addressText.length>0?this.addressText:'',
					// area_level: this.addressText.length>0?3:'',
					page: this.pageIndex,
					limit: this.pageSize
				}
				console.log(data);
				// return;
				this.$api.productList(data).then(res=>{
					this.isLoading = false;
					this.tableData = res.data.data;
					this.pageAllSize = res.data.count;
					for(let i=0; i < this.tableData.length; i++) {
						this.tableData[i]['checked'] = false;
					}
				}).catch(err=>{
					this.isLoading = false;
				})
			},
			//获取地区数据
			getAddressData() {
				this.$api.areaTreeList().then(res=>{
					this.optionsAddress = res.data;
				})
			},
			//获取分类列表,分类状态
			getProClass() {
				this.$api.cateList().then(res=>{
					this.proClass = res.data;
				})
			},
			//删除 
			deteleClick(e) {
        this.deteleData.data[0] = e;
				// this.deteleData.show = true;
        this.$confirm('是否确认删除当前产品？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.productDel({id: e}).then(res=>{
            this.$message({type: 'success', message:'删除成功！'});
            this.initData();
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
			},
			//删除确认
/*			enterDelete() {
				this.deteleData.show = false;
				this.$api.productDel({id: this.deteleData.data}).then(res=>{
					this.$message({type: 'success', message:'删除成功！'});
					this.initData();
				})
			},*/
			//上架、下架
			getUpdateStatu(id, num) {
				let status = num==1?2:1;
				this.$api.productStatus({id: id, status: status}).then(res=>{
					this.$message({type: 'success', message:'操作成功！'});
					this.initData();
				})
			},
			//全选下架事件调用接口
			selectAllStatu() {
				if (this.deteleData.data.length>0) {
					this.$api.productStatus({id: this.deteleData.data, status: 2}).then(res=>{
						this.$message({type: 'success', message:'下架成功！'});
						this.initData();
					})
				}
			},
			//全选事件
			selectionChange(e) {
				this.deteleData.data = [];
				for (let i = 0; i < e.length; i++) {
					this.deteleData.data.push(e[i].id);
				}
			},
			//下架全选
			selectAllClick(e) {
				if (e) {
					this.$refs.multipleTable.toggleAllSelection();
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			//查询
			queryClick() {
				this.initData();
			},
			//重置
			resterClick() {
				this.nameText = '';
				this.typeText = [];
				this.statusText = '';
				this.timeList = [];
				this.addressText = [];
				this.queryClick();
			},
			//适用地区
			addressChange(e) {
				console.log(e);
				
			},
			//页面更改时
			currentClick(e) {
				console.log(e);
				this.pageIndex = e;
				this.initData();
			},
			//页面数量更改时
			updatePage(e) {
				this.pageIndex = 1;
				this.pageSize = e;
				this.initData();
			},
			//添加产品
			addProductClick() {
				this.$router.push({path: '/addProduct', query: {type: 'add'}});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.product-library-main{padding: 18px;width:100%; height: 100%;box-sizing: border-box; 
		.data-content{background-color: #fff;padding: 30px 20px;width:100%; min-height: 100%;box-sizing: border-box;
			.add-btn{width: 110px;height: 40px;background: #04CD83;border-radius: 4px;font-size: 14px;color: #fff;text-align: center;line-height: 40px;}
			.query-content{background: #FAFCFE;padding: 20px;
				.input-content{margin: 0 100px 14px 0;
					.title-text{font-size: 12px;color: #21333F;margin-right: 12px;}
					.input-text{width: 280px;height: 34px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;line-height: 34px;font-size: 12px;padding: 0 12px;box-sizing: border-box;}
					.el-cascader{width: 280px;background: #FFFFFF;border-radius: 4px;font-size: 12px;}
					.el-select{width: 140px;height: 34px;background: #FFFFFF;border-radius: 4px;}
					.date-content{width: 280px;height: 34px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;padding-right: 12px;box-sizing: border-box;
						.el-date-picker{height: 34px;}
						.el-input__inner{border:none;background: none;}
						.el-range-input{font-size: 12px;}
						i{font-size: 18px;color: #B6C4D1;}
					}
				}
				.rest-btn-content{margin-left: 60px;
					.r-btn{width: 70px;height: 34px;border-radius: 4px;line-height: 34px;font-size: 12px;text-align: center;margin-right: 10px;}
					.query-btn{background: #3984F5;color: #fff;}
					.res-btn{background: #EEF1F3;color: #6C7880;}
				}
			}
			.tabel-content{margin-top: 12px;
				.text-red{color: #FD5451;}
				.text-blue{color: #3984F5;}
				.info-content{
					.log-img{width: 72px;height: 72px;border-radius: 6px;background: #CEDAE0;margin: 0 20px;}
					.info-title-text{font-size: 16px;color: #242D33;}
					.info-type-text{font-size: 12px;color: #444F57;}
					.info-content-text{font-size: 12px;color: #6C7880;}
				}
				.btn-content{margin-right: 30px;
					.edit-img{width: 12px;height: 12px;margin-right: 4px;}
					&:hover{cursor: pointer;}
				}
			}
			.page-content{margin-top: 24px;}
		}
		.xj-text-btn{width: 50px;height: 32px;background: #FFFFFF;border-radius: 2px;border: 1px solid #CEDAE0;font-size: 12px;color: #6C7880;margin-left: 16px;line-height: 32px;}
	}
	::v-deep(.el-range__icon){display: none;}
	::v-deep(.el-input__inner){height: 34px;line-height: 34px;font-size: 12px;}
	::v-deep(.el-range-input){font-size: 12px;}
	
	
</style>